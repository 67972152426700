const defaultTheme = {
  // Dynamic Properties
  primaryColor: '#2185d0',

  // Fixed Properties Start here
  // Define breakpoints
  computerBreakpoint: '992px',
  tabletBreakpoint: '768px',

  // Color
  bodyBackgroundColor: '#f5f5f5',
  helpTextColor: '#9b9b9b',
};

export default defaultTheme;
