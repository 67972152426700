import * as React from 'react';
import './LoginApp.css';
import GoogleLoginComponent from './components/GoogleLogin';

class App extends React.Component {
  render() {
    return (
      <div style={{ textAlign: 'center' }}>
          <GoogleLoginComponent/>
      </div>
    );
  }
}

export default App;
