import * as _ from 'lodash';
/*
 * Use this file for globals
 * This file is a good place to read variables from config and export them
 * We do not want to read directly from config in many modules, because the way
 * config is handled can change in the future. If config handling changes, we only need
 * to change this file.
 */

const config = require('./config.js').default;
const mergedConfig = config.SHOW_DEV_TOOLS ?
  {
    ...config,
    ...JSON.parse(window.localStorage.getItem('dev-tools-config') || '{}')
  } : 
  {...config};
/*
 * Read from config, and set appropriate defaults
 * Config may not have all variable set, but globals will set all required variables
 * so that importing modules have no surprises
 */

const INTERNAL_DASHBOARD = '/internal-dashboard';
const INTERNAL_USER = '/internal-user';
const INTERNAL_CARRIER = '/internal-carrier';
const INTERNAL_AWS = '/internal-aws';
export const INTERNAL_MASTERDATA = '/internal-masterdata';

export const PROJECTX_BASE_URL = mergedConfig.PROJECTX_BASE_URL;
export const CT_BASE_URL = mergedConfig.CT_INTERNAL_BASE_URL;
export const DEAL_LETTER_URL = mergedConfig.DEAD_LETTER_BASE_URL;
export const CUSTOM_DEPLOYMENT_BACKEND_URL = mergedConfig.CUSTOM_DEPLOYMENT_BACKEND_URL;
export const DATABASE_MAINTENANCE_URL = mergedConfig.DATABASE_MAINTENANCE_URL;
export const HYBRID_CACHE_MANAGEMENT = mergedConfig.HYBRID_CACHE_MANAGEMENT;
export const CT_INTERNAL_BASE_URL = `${CT_BASE_URL}${INTERNAL_DASHBOARD}`;
export const CT_INTERNAL_CARRIER_BASE_URL = `${CT_BASE_URL}${INTERNAL_CARRIER}`;
export const CT_INTERNAL_AWS_BASE_URL = `${CT_BASE_URL}${INTERNAL_AWS}`;
export const CT_INTERNAL_USER_BASE_URL = `${CT_BASE_URL}${INTERNAL_USER}`;
export const SCRIPT_AUTOMATION_BASE_URL = mergedConfig.SCRIPT_AUTOMATION_BASE_URL;
export const NAVBAR_HEIGHT = 56;
export const MOBILE_BREAK_POINT = 590;
export const ENVIRONMENT = mergedConfig.ENVIRONMENT;
export const EXIM_DASHBOARD_URL = mergedConfig.EXIM_DASHBOARD_URL;
export const GOOGLE_MAPS_API_KEY = mergedConfig.GOOGLE_MAPS_API_KEY;
export const SHOW_DEV_TOOLS = config.SHOW_DEV_TOOLS;
export const IS_FINAL_CONFIG_EQUAL = _.isEqual(mergedConfig, config);
export const JOB_SCHEDULER_BASE_URL = mergedConfig.JOB_SCHEDULER_BASE_URL;
export const BOT_BASE_URL = mergedConfig.BOT_BASE_URL;
export const LOW_CODE_BASE_URL = mergedConfig.LOW_CODE_BASE_URL;
export const LTL_BASE_URL = mergedConfig.LTL_BASE_URL;
export const DATABASE_MIGRATION_TOOL_URL = mergedConfig.DATABASE_MIGRATION_TOOL_URL;
export const WAR_COMMAND_CENTRE_BASE_URL = mergedConfig.WAR_COMMAND_CENTRE_BASE_URL;
