import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { GoogleLogin } from 'react-google-login';
import injectSheet from 'react-jss';
import Cookies from 'universal-cookie';
import * as ns from  '../api/networkService';
import config from '../../config';

const styles = {
  googleButton: {
    width: '20%',
  },
  errorMessage: {
    color: 'red',
    marginBottom: '16px',
  },
};

class GoogleLoginComponent extends React.PureComponent<any, any> {

  state = {
    loading: false,
    isError: false,
    errorMessage: null as any,
  };

  handleRegistration = async (authCode) => {

    const response = await ns.register(authCode);
    if (!response.isSuccess) {
      this.setState({ isError: true, errorMessage: response.errorMessage, loading: false });
    } else {
      const userProfile = response.userProfile;
      this.setState({ loading: false });
      const cookies = new Cookies();
      const objectToEncode = userProfile;
      cookies.set('sessionDetails', btoa(JSON.stringify(objectToEncode)), { sameSite: 'strict' });

      if (!userProfile)
        return;

      if (userProfile.userId)
        window.localStorage.setItem('userId', userProfile.userId);

      if (userProfile.apiKey)
        window.localStorage.setItem('apiKey', userProfile.apiKey);

      if (!!userProfile.userId && !!userProfile.apiKey) {
        window.localStorage.setItem('isSignedIn', 't');
        (window as any).location = '/dashboard';
      }
    }
  }

  responseSuccess = async (response) => {
    const authCode = response.code;
    const data = { authCode };

    await this.handleRegistration(data);
  }

  responseFailure = () => {
    const loginBar = document.getElementById('loginBar');
    ReactDOM.render(<div id="googleButton">
    <GoogleLogin
      clientId={config.GOOGLE_CLIENT_ID}
      buttonText="Sign in with Google"
      scope="https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/spreadsheets"
      onSuccess={this.responseSuccess}
      onFailure={this.responseFailure}
      accessType="offline"
      responseType="code"
    /> </div>, loginBar);
  }

  render() {
    const { classes } = this.props;
    const { errorMessage, isError, loading } = this.state;
    return (
      <div>
      {
        isError ?
        <div className={classes.errorMessage}>
          {errorMessage}
        </div>
        :
        null
      }
      <div id="loginBar">
        <div id="googleButton">
        <GoogleLogin
          clientId={config.GOOGLE_CLIENT_ID}
          buttonText="Sign in with Google"
          scope="https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/spreadsheets"
          onSuccess={this.responseSuccess}
          onFailure={this.responseFailure}
          accessType="offline"
          responseType="code"
        /> </div>
      </div>
      </div>
    );
  }
}

export default injectSheet(styles)(GoogleLoginComponent);
