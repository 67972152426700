import apiProvider from '../api';
import { SCRIPT_AUTOMATION_BASE_URL } from '../../globals';
import Cookies from 'universal-cookie';
/*
const getErrorMessage = (err) => {
  let errorMessage = '';
  if (!err.response) {
    errorMessage = `Could not connect. Please check your internet connection`;
  } else {
    const responseData = err.response.data;
    if (responseData && responseData.error) {
      errorMessage = responseData.error.message;

    } else {
      errorMessage = `Something went wrong\n${err.message}`;
    }
  }
  return errorMessage;
};*/

export const register = async (data) => {
  try {
    const response = await apiProvider.post(
      `${SCRIPT_AUTOMATION_BASE_URL}/users/signin`,
      data,
      {},
    );

    if (!response) {
      return {
        isSuccess: false,
        errorMessage: 'Failed to register. Please try again!',
      };
    }

    if (response.data.status === 'ERROR') {
      if (response.data.message) {
        return {
          isSuccess: false,
          errorMessage: response.data.message,
        };
      }
    }

    return {
      isSuccess: true,
      userProfile: response.data.data,
    };

  } catch (err) {
    return {
      isSuccess: false,
      errorMessage: err.message,
    };
  }
};

/* export const loginUser = async (data) => {
  try {
    const response = await apiProvider.post(
      PROJECTX_BASE_URL + '/api/internal/dashboard/login',
      data,
      {},
    );
    return {
      isSuccess: true,
      data: response.data.data,
      errorMessage: null,
    };

  } catch (err) {
    const errorMessage = getErrorMessage(err);

    return {
      errorMessage,
      isSuccess: false,
    };
  }
};*/
