import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { JssProvider, ThemeProvider } from 'react-jss';
import { create as createJss } from 'jss';
import preset from 'jss-preset-default';

// Use require. Importing will not work with hot reload
const App = require('./LoginApp').default;

import { AppContainer } from 'react-hot-loader';

import defaultTheme from '../theming/defaultTheme';

const localJss = createJss({
  ...preset(),
});

const render = (Component: any) => {
  ReactDOM.render(
    <AppContainer>
      <JssProvider jss={localJss}>
        <ThemeProvider theme={defaultTheme}>
          <Component />
        </ThemeProvider>
      </JssProvider>
    </AppContainer>
    ,
    document.getElementById('root'),
  );
};

render(App);

if (module.hot && process.env.NODE_ENV !== 'production') {
  console.log('hot is enabled');
  // module.hot.accept();
  module.hot.accept('./LoginApp', () => {
    console.log('inside new');
    const NextApp = require('./LoginApp').default;
    render(NextApp);
  });
}
